import React from 'react';
import { Helmet } from 'react-helmet';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

export const Osano = () => {
  if (!gatsbyConfig?.osano) return null;

  // TODO: Remove WA and CO from list before pushing to production.
  // If you see this comment and us-wa and us-co are in the list below, please remove it
  // and then remove this TODO
  return (
    <Helmet>
      <script src={`https://cmp.osano.com/${gatsbyConfig.osano.customerId}/${gatsbyConfig.osano.configId}/osano.js`} />
      <script>
        {`
          // Pre-load Osano Script
          ;(function(w,o,d){w[o]=w[o]||function(){w[o][d].push(arguments)};w[o][d]=w[o][d]||[]})(window,'Osano','data');

          //
          window.Osano('onInitialized', () => {
            // create script tag
            var script = document.createElement('script');

            // list of regions that require different banner variation
            var granularConsentBannerJurisdictions = [
              'us-ca',
              'us-wa',
              'us-co',
              'at',
              'be',
              'bg',
              'hr',
              'cy',
              'cz',
              'dk',
              'ee',
              'fi',
              'fr',
              'de',
              'gr',
              'hu',
              'ie',
              'it',
              'lv',
              'lt',
              'lu',
              'mt',
              'nl',
              'pl',
              'pt',
              'ro',
              'sk',
              'si',
              'es',
              'se'
            ];

            console.log('granularConsentBannerJurisdictions', granularConsentBannerJurisdictions);
            console.log('jurisdiction reported by Osano', window.Osano.cm.jurisdiction);

            // check if reported Osano jurisdiction is include in list of regions that need banner different banner variation
            if(granularConsentBannerJurisdictions.includes(window.Osano.cm.jurisdiction)) {
              script.src = 'https://cmp.osano.com/${gatsbyConfig.osano.customerId}/${gatsbyConfig.osano.configId}/osano.js?variant=two';
            }
            else {
              script.src = 'https://cmp.osano.com/${gatsbyConfig.osano.customerId}/${gatsbyConfig.osano.configId}/osano.js';
            }

            // append the src tag to the head of the document
            document.head.appendChild(script);
          });
        `}
      </script>
    </Helmet>
  );
};

Osano.propTypes = {};

Osano.defaultProps = {};
